import {useEffect} from 'react'
import getQueryObject from '@helpers/misc/getQueryObject'
import {useRouter} from 'next/router'

/**
 * When using a shared page for different routes (e.g. /order and /order/productId/productName),
 * we need to force the param (productId) into the router query object.
 * Unfortunatelly, when doing a rewrite in the middleware, the passed query vars are not available.
 * @param resourceKey the key to force onto the router (e.g. 'productId')
 * @param getResourceFromUrlFn a fn that parses the current path and returns the resource value (e.g. getProductIdFromUrl)
 * @returns the router
 */
export default function useSharedPageRewriteHelper(
  resourceKey: string,
  getResourceFromUrlFn: (url: string) => string
) {
  const router = useRouter()

  useEffect(() => {
    // We use useEffect so that the cached SSR version does not render the resource. It should render the wrapper instead.
    const matchedResource = getResourceFromUrlFn(router.asPath)
    if (router.isReady && matchedResource && router.query[resourceKey] !== matchedResource) {
      const query = getQueryObject(router)
      router.replace({
        pathname: window.location.pathname,
        query: {...query, [resourceKey]: matchedResource}
      })
    }
  }, [router, resourceKey, getResourceFromUrlFn])

  return router
}
