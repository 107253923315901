import {getProductIdFromOrderUrl} from '@helpers/middleware/sharedPages/product'
import {NextRouter} from 'next/router'

export function parseProductIdFromPath(router: NextRouter) {
  if (router.asPath.includes('?')) {
    const queryParams = new URLSearchParams(router.asPath.split('?')[1])
    if (queryParams.has('productId')) {
      return queryParams.get('productId')
    }
  }
  return getProductIdFromOrderUrl(router.asPath)
}
