import {NextURL} from 'next/dist/server/web/next-url'
import {NextResponse} from 'next/server'

import getTenantPath from '../getTenantPath'

export const getProductIdFromOrderUrl = (pathname: string) => {
  const match = pathname.match(/^\/(order|pedir)\/([^\/\?]+)\/?([^\/]*)/)

  if (!match) return null
  if (match[2] === 'category') return null
  return match[2]
}

export const rewriteProduct = (
  url: NextURL,
  pathname: string,
  viewport: 'mobile' | 'desktop',
  hostname: string
) => {
  const productId = getProductIdFromOrderUrl(pathname)

  if (productId) {
    url.pathname = `${getTenantPath(viewport, hostname)}/order`
    url.searchParams.set('productId', productId)
    return NextResponse.rewrite(url)
  }

  return null
}
